import React from "react";
import { Router } from "@reach/router";

import { ClientOnly } from "../../components";
import { ProtectedRoute } from "../../core/auth";

import Login from "./login";
import Checkout from "./checkout";
import LoginCallback from "./login/callback";

const Points = () => (
  <ClientOnly>
    <Router basepath="/points">
      <LoginCallback path="/login/callback" />

      <ProtectedRoute
        mode={ProtectedRoute.MODE.CUSTOMER}
        path="/checkout"
        as={Checkout}
        SignInComponent={Login}
      />
    </Router>
  </ClientOnly>
);

export default Points;
